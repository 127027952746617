import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  const [recommendedLink, setRecommendedLink] = useState("/");
  const [recommendedLinkLabel, setRecommendedLinkLabel] = useState("Go to home page");

  useEffect(() => {
    if (typeof document !== "undefined") {
      const pathname = document?.location?.pathname;
      if (pathname && pathname.includes("/word/german/english")) {
        setRecommendedLink("/courses/german-vocabulary-word-lists-in-english/");
        setRecommendedLinkLabel("Browse German word lists");
      }
    }
  }, []);

  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to={recommendedLink}>{recommendedLinkLabel}</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
